import React from 'react';
import {
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, KeyboardShortcut } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../data/snippets/keyboard-shortcut.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, KeyboardShortcut: KeyboardShortcut }}
        code={snippet}
        platform="react"
        gitHubLink="keyboard-shortcut"
      />
      <Section title="Props">
        <PropList>
          <PropListItem name="children" types={['string']}>
            <Text>
              The key to display. Single character keys will automatically be
              uppercased. Multi-character keys like shift, esc, etc., will
              automatically be lowercased. Pass in the following to get
              direction keys:
            </Text>
            <List type="unordered">
              <li>
                <code>up</code>
              </li>
              <li>
                <code>down</code>
              </li>
              <li>
                <code>left</code>
              </li>
              <li>
                <code>right</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this keyboard shortcut in automated
              tests.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
